import React from 'react';
import { PortableText } from '@portabletext/react';

import QuoteMark from '@/assets/quote-mark.svg';

const quotePortableText = {
  block: {
    normal: ({ children }) => <span>"{children}"</span>,
  },
};

const getChildrenText = (props) =>
  props.children
    .map((node) => (typeof node === 'string' ? node : node.text || ''))
    .join('');

const portableText = {
  types: {
    quote: ({ value }) => {
      const { quote, authorName, authorTitle, authorImage } = value;

      return (
        <div className="w-full rounded-lg border border-black/20 bg-white p-6 md:p-10">
          <QuoteMark />
          <blockquote className="my-4 text-xl italic text-smoky-black">
            <PortableText value={quote} components={quotePortableText as any} />
          </blockquote>
          <div className="flex flex-row gap-4">
            <img
              src={authorImage.asset.url}
              alt={authorName}
              className="h-16 w-16 rounded-full object-center"
            />
            <div className="flex flex-col justify-center text-[#4B5563]">
              <p className="font-semibold">{authorName}</p>
              <p className="font">{authorTitle}</p>
            </div>
          </div>
        </div>
      );
    },
  },
  block: {
    normal: ({ children }) => (
      <p className="my-4 text-base font-normal text-[#4B5563]">{children}</p>
    ),
    h1: ({ node }) => {
      const headingText = getChildrenText(node);
      // remove <br> element from children
      const children = node.children.filter((child) => child._type !== 'br');

      return (
        <h1 className="text-4xl leading-tight text-primary-600 md:text-5xl lg:text-[3.5rem]">
          {children.map((child, index) => (
            <span key={index}>{child.text}</span>
          ))}
        </h1>
      );
    },
    h2: ({ node }) => {
      const headingText = getChildrenText(node);
      // remove <br> element from children
      const children = node.children.filter((child) => child._type !== 'br');

      return (
        <h2 className="text-2xl text-primary-600 md:text-3xl lg:text-4xl">
          {children.map((child, index) => (
            <span key={index}>{child.text}</span>
          ))}
        </h2>
      );
    },
    h3: ({ node }) => {
      const headingText = getChildrenText(node);
      // remove <br> element from children
      const children = node.children.filter((child) => child._type !== 'br');

      return (
        <h3 className="text-3xl font-medium leading-tight text-primary-600 md:text-4xl">
          {children.map((child, index) => (
            <span key={index}>{child.text}</span>
          ))}
        </h3>
      );
    },

    h4: ({ node }) => {
      const headingText = getChildrenText(node);
      // remove <br> element from children
      const children = node.children.filter((child) => child._type !== 'br');

      return (
        <h4 className="text-xl font-medium text-primary-600 lg:text-[32px] lg:leading-10">
          {children.map((child, index) => (
            <span key={index}>{child.text}</span>
          ))}
        </h4>
      );
    },
  },
  list: {
    number: ({ children }) => (
      <ol className="my-4 ml-4 list-outside list-decimal px-4 font-normal">
        {children}
      </ol>
    ),
    bullet: ({ children }) => (
      <ul className="my-4 ml-4 list-outside list-disc">{children}</ul>
    ),
  },
  // listItem: {
  //   bullet: ({ children }) => <li className="my-2">{children}</li>,
  // },
  marks: {
    strong: ({ children }) => <span className="font-bold">{children}</span>,
    underline: ({ children }) => <span className="underline">{children}</span>,
    em: ({ children }) => <span className="italic">{children}</span>,
    subscript: ({ children }) => <sub>{children}</sub>,
    superscript: ({ children }) => <sup>{children}</sup>,
    'strike-through': ({ children }) => (
      <span className="line-through">{children}</span>
    ),
    link: ({ children, value }) => {
      const rel = !value.href.startsWith('/')
        ? 'noreferrer noopener'
        : undefined;
      return (
        <a
          href={value.href}
          rel={rel}
          className="text-blue-800 underline transition-all duration-300 hover:text-purply-blue"
        >
          {children}
        </a>
      );
    },
  },
};

const CaseStudyPostBody = ({ body }) => {
  return (
    <div className="flex w-full flex-col md:w-[70%] lg:pr-32">
      <PortableText value={body} components={portableText as any} />
    </div>
  );
};

export default CaseStudyPostBody;
