import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@/components/seo';
import Layout from '@/components/common/v5/layout';
import Hero from '@/components/case-study-post/hero';
import Summary from '@/components/case-study-post/summary';
import Body from '@/components/case-study-post/body';
import OtherPosts from '@/components/case-study-post/other-posts';
import CTAButtons from '@/components/case-studies/cta-buttons';

const CaseStudyPost = ({ data }) => {
  const {
    title,
    mainImage,
    clientLogo,
    mainImageAlt,
    clientSummary,
    heroColor,
    summary,
    _rawBody,
    ctaTitle,
    ctaLink,
  } = data.sanityCaseStudyPosts;

  // pick random two posts
  // right now this works as there are just 5 posts
  // if there would be more posts, find a better way to pick random posts
  const otherPosts = data.allSanityCaseStudyPosts.nodes
    .sort(() => Math.random() - Math.random())
    .slice(0, 2);
  const otherPostsLinkLabel = data.sanityCaseStudiesPage.cardLinkLabel;
  const otherPostsTitle = data.sanityCaseStudiesPage.moreCaseStudiesLabel;

  const heroData = {
    title,
    mainImage,
    clientLogo,
    mainImageAlt,
    clientSummary,
    heroColor,
  };

  return (
    <Layout>
      <Hero data={heroData} />
      {/*  content */}
      <section className="bg-[#f7f7ff]">
        <div className="mx-auto flex max-w-[90rem] flex-col justify-between gap-10 px-5 py-6 md:flex-row md:gap-2 md:py-16">
          <Summary data={summary} />
          <Body body={_rawBody} />
        </div>
      </section>

      <section className="bg-white py-6 md:py-16 lg:py-20">
        <div className="mx-auto max-w-[90rem] px-5 py-6 md:py-10 lg:py-14">
          <CTAButtons title={ctaTitle} primaryBtn={ctaLink[0]} />
        </div>
        <OtherPosts
          posts={otherPosts}
          title={otherPostsTitle}
          cardLinkLabel={otherPostsLinkLabel}
        />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query CaseStudyPost($slug: String!) {
    sanityCaseStudyPosts(slug: { current: { eq: $slug } }) {
      seo {
        metaTitle
        metaDescription
        image {
          asset {
            extension
            metadata {
              dimensions {
                height
                width
              }
            }
            gatsbyImageData(width: 1280)
            url
            localFile(width: 1280) {
              publicURL
              childImageSharp {
                fixed(width: 1280) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
      }

      title
      slug {
        current
      }
      clientLogo {
        asset {
          url
        }
      }
      mainImage {
        asset {
          gatsbyImageData(width: 1440)
          url
        }
      }
      mainImageAlt
      heroColor
      clientSummary {
        title
        subtitle
      }
      summary {
        head {
          title
          productLogos {
            asset {
              url
            }
          }
        }
        items {
          title
          _rawText(resolveReferences: { maxDepth: 10 })
        }
        ctaLabel
        ctaLink {
          ... on SanityExternalLink {
            _type
            label
            url
          }
          ... on SanityInternalLink {
            isCta
            label
            slug {
              current
            }
            _type
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      ctaTitle
      ctaLink {
        ... on SanityExternalLink {
          _type
          label
          url
        }
        ... on SanityInternalLink {
          isCta
          label
          slug {
            current
          }
          _type
        }
      }
    }

    allSanityCaseStudyPosts(
      sort: { fields: date, order: DESC }
      filter: { slug: { current: { ne: $slug } } }
    ) {
      nodes {
        title
        slug {
          current
        }
        clientLogo {
          asset {
            url
          }
        }
        mainImage {
          asset {
            gatsbyImageData(width: 930)
            url
          }
        }
        mainImageAlt
        heroColor
      }
    }

    sanityCaseStudiesPage {
      cardLinkLabel
      moreCaseStudiesLabel
    }
  }
`;

export default CaseStudyPost;

export const Head = ({ data }) => {
  const { seo } = data.sanityCaseStudyPosts;

  return (
    <SEO
      title={seo?.metaTitle || null}
      description={seo?.metaDescription || null}
      image={seo && seo.image ? seo.image.asset.localFile.publicURL : null}
      imageWidth={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.width
          : null
      }
      imageHeight={
        seo && seo.image
          ? seo.image.asset.localFile.childImageSharp.fixed.height
          : null
      }
    />
  );
};
